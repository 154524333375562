import { border, extendTheme } from '@chakra-ui/react';

const theme = {
    config: {
        initialColorMode: 'dark',
        useSystemColorMode: false,
    },
    global: {
        body: {
            color: 'white',
            fontFamily: 'Nunito',
        },
    },
    styles: {
        global: () => ({
            ".gradient-text": {
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                textFillColor: "transparent",
                WebkitTextFillColor: "transparent",
            },
        })
    },
    colors: {
        toxic: '#3AD185',
        lava: '#EBB107',
        ocean: "#6FD4F9",
        bloody: "#F04C71",
        hyper: "#B77DB9",
        white: "#fff",
        barbiePink: '#FF457D',
        dark: {
            main: "#000",
            num: "#2B2B37",
            navyBlue: "#202A47",
            darkBlue: "#202A47",
            darkGrey: "#4D546C",
            black: "#000000",
        },
        bg: {
            400: '#4D546C',
            500: '#2B2B37',
            800: "#202947",
            900: '#000',
        },
        gradient: {
            lava: 'linear-gradient(119deg, #F2C94E 0.11%, #DDAD1E 97.39%)',
            hyper: 'linear-gradient(285deg, #A84AAB 8.58%, #BB79BE 105.17%)',
            ocean: 'linear-gradient(302deg, #7EDDFF -6.25%, #51C2EC 106.35%)',
            toxic: 'linear-gradient(301deg, #21C78B -6.25%, #57DC7C 168.87%)',
            bloody: 'linear-gradient(302deg, #FF6060 -6.25%, #DE3486 106.35%)',
            white: 'linear-gradient(285deg, #ffffff 8.58%, #ffffff 105.17%)'
        },
    },
    components: {
        Link: {
            baseStyle: {
                '& > svg': {
                    transition: "all 0.3s"
                },
                "&:hover > svg": {
                    color: '#F2C94E!important',
                },
            },
            variants: {
                header: {
                    textDecoration: "none !important",
                    "& *": {
                        transition: "all 0.3s"
                    },
                    _hover: {
                        "& *": {
                            color: "#F2C94E",
                            textDecoration: "none !important"
                        }
                    },
                },
                footer: {
                    textDecoration: "none !important",
                    "& *": {
                        transition: "all 0.3s",
                        opacity: "0.75 !important",
                        color: "white",
                    },
                    _hover: {
                        "& *": {
                            opacity: "1 !important",
                            color: "#F2C94E",
                            textDecoration: "none !important"
                        }
                    },
                }
            }
        },

        Container: {
            baseStyle: {
                py: 10,
                px: { base: 4, md: 20 },
                maxW: '1440px',
            },
        },
        Heading: {
            baseStyle: {
                color: 'white',
                fontFamily: 'Nunito',
            },
            variants: {
                h1: {
                    fontWeight: 900,
                    fontSize: '280px',
                    lineHeight: '242px',
                },
                h2: {
                    fontWeight: 900,
                    fontSize: ['70px', '70px', '150px', '150px', '150px'],
                    lineHeight: ['76px', '76px', '116px', '116px', '116px'],
                },
                h3: {
                    fontWeight: 900,
                    fontSize: ['40px', '40px', '100px', '100px', '100px'],
                    lineHeight: 'normal',
                },
                h3MobileHuge: {
                    fontWeight: 900,
                    fontSize: ['70px', '70px', '100px', '100px', '100px'],
                    lineHeight: 'normal',
                },
                H3: {
                    fontWeight: 900,
                    fontSize: '100px',
                    lineHeight: '116px',
                },
                h4: {
                    fontWeight: 900,
                    fontSize: '80px',
                    lineHeight: 'normal',
                },
                h5: {
                    fontWeight: 900,
                    fontSize: ["32px", "32px", "48px", "48px"],
                    lineHeight: ["32px", "32px", "48px", "48px"],
                },
                H5: {
                    fontWeight: 900,
                    fontSize: ["32px", "32px", "48px", "48px"],
                    lineHeight: '50px',
                    textTransform: 'uppercase',
                },
                captionBig: {
                    fontWeight: 900,
                    fontSize: '24px',
                    lineHeight: 'normal',
                },
                bodyBig: {
                    fontWeight: 500,
                    fontSize: '20px',
                    lineHeight: 'normal',
                },
                bodyMonster: {
                    fontWeight: 500,
                    fontSize: "32px",
                    lineHeight: '48px',
                },
                bodyMonsterFat: {
                    fontWeight: 900,
                    fontSize: ['24px', '24px', '32px', '32px'],
                    lineHeight: 'normal',
                },
                bodyBigFat: {
                    fontWeight: 900,
                    fontSize: '20px',
                    lineHeight: 'normal',
                },
                bodyMediumFat: {
                    fontWeight: 900,
                    fontSize: '16px',
                    lineHeight: 'normal',
                },
                bodyNormal: {
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: 'normal',
                },
                captionNormal: {
                    fontWeight: 900,
                    fontSize: '18px',
                    lineHeight: 'normal',
                },
                bodyNormalBold: {
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: 'normal',
                },
            }
        },
        Text: {
            baseStyle: {
                color: 'white',
                fontFamily: 'Nunito',
            },
            variants: {
                h3: {
                    fontWeight: 900,
                    fontSize: ['40px', '40px', '100px', '100px', '100px'],
                    lineHeight: 'normal',
                },
                captionBig: {
                    fontWeight: 900,
                    fontSize: '24px',
                    lineHeight: 'normal',
                },
                captionNormal: {
                    fontWeight: 900,
                    fontSize: '18px',
                    lineHeight: 'normal',
                },
                bodyBig: {
                    fontWeight: 500,
                    fontSize: '20px',
                    lineHeight: 'normal',
                },
                bodyMonster: {
                    fontWeight: 500,
                    fontSize: ['26px', '32px', '32px', '32px'],
                    lineHeight: ['38px', '48px', '48px', '48px'],
                },
                bodyMonsterFat: {
                    fontWeight: 900,
                    fontSize: '32px',
                    lineHeight: 'normal',
                },
                bodyBigFat: {
                    fontWeight: 900,
                    fontSize: '20px',
                    lineHeight: 'normal',
                },
                bodyBigBold: {
                    fontWeight: 700,
                    fontSize: '20px',
                    lineHeight: 'normal',
                },
                bodyMediumFat: {
                    fontWeight: 900,
                    fontSize: '16px',
                    lineHeight: 'normal',
                },

                bodyNormal: {
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: 'normal',
                },
                bodyNormalFat: {
                    fontWeight: 900,
                    fontSize: '14px',
                    lineHeight: 'normal',
                },
                normalFat: {
                    fontWeight: 900,
                    fontSize: '14px',
                    lineHeight: 'normal',
                },
                bodyRegular: {
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: 'normal',
                },
                bodySmall: {
                    fontWeight: 900,
                    fontSize: '12px',
                    lineHeight: 'normal',
                },
                H4: {
                    fontWeight: 900,
                    fontSize: '80px',
                    lineHeight: 'normal',
                },
            },
        },
        Button: {
            baseStyle: {
                color: 'white',
                fontWeight: 900,
                borderRadius: 90,
                lineHeight: 'normal',
                fontFamily: 'Nunito',
            },
            variants: {
                solid: {
                    color: 'white',
                    textTransform: 'uppercase',
                },
                ghost: {
                    color: 'white',
                    bg: 'transparent',
                    '&:hover': {
                        color: '#21C78B',
                        bg: 'transparent',
                    },
                }
            },
            sizes: {
                md: {
                    px: 8,
                    py: 6,
                    height: 'auto',
                },
            },
        },
    },
    textStyles: {
        captionBig: {
            fontSize: {
                base: '24px',
            },
            fontWeight: 900,
        }
    }
};

export default extendTheme(theme);
